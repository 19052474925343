<template>
    <ConfirmDialog ref="confirmDialog"/>
    <div class="merge-fields">
        <div v-if="scope" class="merge-fields__content">

            <InstanceInfo :name-disabled="true" :info-list="infoList" :date="lastUpdateDescription(scope.last_activity)"
                          :name="scope.name"/>

            <h3 class="merge-fields__title">Confirm Data</h3>

            <div class="merge-fields__instructions">
                Please review the merge fields below to insure data accuracy and missing data
                <small>Use checkbox to ignore missing data</small>
            </div>

            <div v-if="mergeFields.length" class="merge-fields__table" :aria-disabled="loading">
                <div v-for="field in mergeFields" :key="field.id"
                     :id="`merge-field__${ field.id }`" class="merge-fields__table-item">

                    <div class="merge-fields__table-name">{{ field.shortcode.name }}</div>

                    <div v-if="field.is_checkbox" class="input-group">
                        <input type="checkbox" class="mr-2" :checked="field.value === '1'"
                               @change="onCheckboxFieldChange(field)">
                    </div>

                    <OptionsDropdown v-else-if="field.options" :options="field.options" :model-value="field.value"
                                     label="Select an option" @update:modelValue="(value) => (field.value = value)"
                                     class="input-group"/>

                    <CInput v-else v-model="field.value" :invalid-feedback="errorFields.includes(field.id)"
                            :placeholder="field.shortcode.placeholder" @input="onFieldChange($event, field)"></CInput>

                    <div class="merge-fields__table-checkbox">
                        <input class="form-check-input" type="checkbox" :checked="field.nullable"
                               @change="onNullableCheckboxChange(field)"/>
                    </div>

                    <small v-if="errorFields.includes(field.id)" class="merge-fields__table-error">
                        The field is required
                    </small>

                </div>
                <div v-for="signature in signatureFields" class="merge-fields__table-item">
                    <div class="merge-fields__table-name">{{ signature }}</div>
                    <span>Included & Requested</span>
                </div>
            </div>

            <div v-if="mergeFields.length === 0 && signatureFields.length === 0 && !loading"
                 class="merge-fields__table-empty">
                No merge fields found
            </div>

            <StickyFooter class="merge-fields__footer">
                <CButton color="primary" variant="outline" :disabled="loading" @click="submitForm(true)">
                    Save draft
                </CButton>

                <CButton color="primary" :disabled="loading" @click="submitForm()">Save & next</CButton>

                <CSpinner v-show="loading" color="primary"/>
            </StickyFooter>

        </div>
        <div class="merge-fields__loading" v-else>
            <CSpinner color="primary"/>
        </div>
    </div>
</template>

<script>

import InstanceInfo from "@/components/Forms/Partials/InstanceInfo.vue";
import CInput from "@/components/CInput.vue";
import StickyFooter from "@/components/Forms/Partials/StickyFooter.vue";
import ConfirmDialog from "@/components/Modals/ConfirmDialog.vue";
import OptionsDropdown from "@/components/CreateTask/OptionsDropdown.vue";
import unsavedChanges from "@/mixin/unsavedChanges";
import recordActivity from "@/mixin/recordActivity";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import Scopes from "@/api/v2/endpoints/Scopes";
import pushCommitmentHelper from "@/mixin/scopes/pushCommitmentHelper";

export default {
    name: "MergeFields",
    inject: ['toast'],
    mixins: [unsavedChanges, recordActivity, apiErrorHandler, pushCommitmentHelper],
    components: {OptionsDropdown, ConfirmDialog, StickyFooter, CInput, InstanceInfo},
    async beforeRouteLeave() {
        return this.triggerUnsavedChangesCheck(this.changesCommited);
    },
    data() {
        return {
            scope: null,
            mergeFields: [],
            signatureFields: [],
            errorFields: [],
            loading: false,
            changesCommited: false
        }
    },
    computed: {
        infoList() {
            return [
                {label: 'Selected package', value: this.scope.template_folder.name},
                {label: 'Company Name (Sub)', value: this.scope.external_company.name},
                {label: 'Default Signer Name (Sub)', value: this.scope.external_signer.full_name},
            ];
        },
    },
    async created() {
        await this.loadScopeData();
        this.$emit('lastAvailableStep', this.scope.step)
        this.loadMergeFields();
    },
    methods: {
        async loadScopeData() {
            await Scopes
                .show(this.$route.params.scopeId, {
                    scope_data: 'id,name,status,step',
                    with_external_company: 'name',
                    with_external_signer: 'full_name',
                    with_template_folder: 'name',
                    with_unassigned_tasks_amount: 1,
                    with_last_activity: 1,
                })
                .then(async response => {
                    await this.checkPushCommitmentStep(response.data.step);
                    this.scope = response.data;
                });
        },
        loadMergeFields() {
            this.toggleLoading();

            Scopes
                .pushCommitmentStepThreeSetup(this.scope.id)
                .then(response => {
                    this.mergeFields = response.data;
                    this.signatureFields = response.meta.signatures;
                })
                .finally(() => this.toggleLoading());
        },
        onFieldChange(e, field) {
            field.value = e.target.value
            this.changesCommited = true
            this.removeFieldFromErrorFields(field.shortcode_id)
        },
        onCheckboxFieldChange(field) {
            field.value = field.value === '1' ? '0' : '1';
        },

        onNullableCheckboxChange(field) {
            this.changesCommited = true;
            field.nullable = !field.nullable;
            this.removeFieldFromErrorFields(field.shortcode_id)
        },
        removeFieldFromErrorFields(id) {
            this.errorFields = this.errorFields.filter(codeId => codeId !== id)
        },
        validateMergeFields() {
            this.mergeFields.forEach(field => {
                if (!field.value && !field.nullable)
                    this.errorFields.push(field.id);
            })

            this.scrollToError()
        },
        scrollToError() {
            if (!this.errorFields.length) return

            const firstErrorElement = document.getElementById(`merge-field__${this.errorFields[0]}`);
            const y = firstErrorElement.getBoundingClientRect().top + window.scrollY - 100;

            window.scroll({
                top: y,
                behavior: 'smooth'
            });
        },
        submitForm(isDraft = false) {
            this.errorFields = [];

            if (!isDraft) {
                this.validateMergeFields();
            }

            if (this.errorFields.length === 0) {
                this.saveMergeFields(isDraft)
            }
        },
        saveMergeFields(isDraft) {
            this.toggleLoading();

            Scopes
                .pushCommitmentStepThreeSubmit(this.scope.id, this.defineMergeFieldsData(isDraft))
                .then(() => {
                    this.changesCommited = false;
                    this.toast('info', 'Changes have been saved successfully')

                    if (!isDraft) {
                        this.$router.push({name: 'ProjectScopesTaskConfirm'})
                    }
                })
                .finally(() => this.toggleLoading())
        },
        defineMergeFieldsData(isDraft) {
            return {
                is_draft: isDraft,
                shortcode_realizations: this.mergeFields
                    .map(({id, value, nullable}) => ({id, value, nullable: !!nullable}))
            };
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    }
}
</script>

<style lang="scss">
.merge-fields {
    background: white;
    border-radius: 0 0 8px 8px;
    padding: 24px;

    &__footer {

        & .spinner-border {
            height: 25px;
            width: 25px;
        }
    }

    & .input-group {
        width: unset;
        flex: auto;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        color: #1C262F;
        margin-bottom: 0;
    }

    &__table {
        border-top: 1px solid #DEE4E8;

        &-empty {
            border-radius: 8px;
            background: #F2F5F8;
            text-align: center;
            padding: 25px;
            color: #677A89;
        }

        &-checkbox {
            margin-left: 24px;
        }

        &-name {
            width: 400px;
            font-size: 16px;
            color: #1C262F;
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 24px 16px;
            position: relative;

            &:nth-of-type(even) {
                background: #F2F5F8;
            }
        }

        &-error {
            color: red;
            position: absolute;
            left: calc(400px + 16px);
            bottom: 5px;
            font-size: 12px;
        }
    }

    &__instructions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #677A89;

        & small {
            font-size: 12px;
        }
    }

    &__loading {
        display: flex;
        justify-content: center;
        padding: 150px 0;
    }
}
</style>
